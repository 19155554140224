module.exports={
  "name": "@uppy/dashboard",
  "description": "Universal UI plugin for Uppy.",
  "version": "1.17.0",
  "license": "MIT",
  "main": "lib/index.js",
  "style": "dist/style.min.css",
  "types": "types/index.d.ts",
  "keywords": [
    "file uploader",
    "uppy",
    "uppy-plugin",
    "dashboard",
    "ui"
  ],
  "homepage": "https://uppy.io",
  "bugs": {
    "url": "https://github.com/transloadit/uppy/issues"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/transloadit/uppy.git"
  },
  "dependencies": {
    "@transloadit/prettier-bytes": "0.0.7",
    "@uppy/informer": "file:../informer",
    "@uppy/provider-views": "file:../provider-views",
    "@uppy/status-bar": "file:../status-bar",
    "@uppy/thumbnail-generator": "file:../thumbnail-generator",
    "@uppy/utils": "file:../utils",
    "classnames": "^2.2.6",
    "cuid": "^2.1.1",
    "is-shallow-equal": "^1.0.1",
    "lodash.debounce": "^4.0.8",
    "lodash.throttle": "^4.1.1",
    "memoize-one": "^5.0.4",
    "preact": "8.2.9",
    "resize-observer-polyfill": "^1.5.0"
  },
  "peerDependencies": {
    "@uppy/core": "^1.0.0"
  }
}
